import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }
  fetchResults(e) {
    e.preventDefault()
    const url = this.element.dataset.url
    const urlValues = e.target.value.length > 0 ? new URLSearchParams({category: e.target.value}) : ''
    const element = this.element
    fetch(`${url}?${urlValues}`).
    then(response => response.text()).
    then(html => {
      element.innerHTML = html
    })
  }
  fetchPaginatedResults(e) {
    e.preventDefault()
    const url = e.target.href
    const element = this.element
    fetch(`${url}`,{ headers: {"Content-Type": "application/json"}}).
    then(response => response.text()).
    then(html => {
      element.innerHTML = html
    })
  }
}

