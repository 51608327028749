// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
//= require_tree .

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch'
import 'element-closest-polyfill'
import 'focus-visible/dist/focus-visible.min.js'
// import "core-js/modules/es7.promise.finally";
import CustomEvent from 'custom-event-polyfill';
import objectFitImages from 'object-fit-images';
const SmoothScroll = require('smooth-scroll')

import * as u from 'JS/utils.js'
u.domReady(function() {
  // Object-fit & object-position support for older browsers
  // Use example: Along with css propertief for object-fit you should add this:
  // font-family: "object-fit: cover; object-position: center center;";
  objectFitImages('img', {watchMQ: true});

  // Init smoothScroll
  const scroll = new SmoothScroll('a.scrollto', {
    header: '.header'
  });
});
document.addEventListener('turbolinks:load', function (event) {
  window.scrollTo(0, 0)
  if(typeof ga === 'function') {
    ga('set', 'location', event.data.url)
    ga('send', 'pageview')
  }
  if(typeof gtag === 'function') {
    gtag('config', '<%= Slickr::Setting["google_tag_identifier"] %>', {
      'page_location': event.data.url
    });
  }
})
// ------------------

// Gather all js files from components folders
import './main';

