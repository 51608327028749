import { Controller } from "stimulus"
import Plyr from 'plyr'

export default class extends Controller {
  static targets = ['player']

  connect() {
    const player = new Plyr(this.playerTarget)
  }
}
