import * as u from 'JS/utils'

u.domReady(function() {
  const media = document.querySelector('.js-accordion-images')
  const images = document.querySelectorAll('.js-accordion-image')
  const inputs = document.querySelectorAll('.js-accordion-input')

  if (media !== null) {
    Array.from(inputs).forEach(function(input) {
      input.addEventListener('change', function(e) {
        const id = e.target.id
        const checked = e.target.checked
        const activeImage = media.querySelector('.js-accordion-image[data-image-for="' + id + '"]')

        if (checked) {
          clearAllActive(images)
          activeImage.classList.add('active')
        }
      })
    });
  }
})

const clearAllActive = function(nodes, classNameToRemove) {
  const klass = classNameToRemove || 'active'
  Array.from(nodes).forEach( function(item) {
    item.classList.remove(klass)
  })
}

u.domReady(function() {
  const el = document.getElementById('cookies-bar')
  const close = el.querySelector('button')

  let accepted = localStorage.getItem('farrpoint_cookie_accepted') || false

  if (!accepted) {
    el.classList.remove('closed')
  }

  close.addEventListener('click', function(e) {
    el.classList.add('closed')
    localStorage.setItem('farrpoint_cookie_accepted', true)
  })
})

u.domReady(function() {
  const body = document.getElementsByTagName('body')[0]
  const nav = document.getElementsByClassName('header-navigation')[0]
  const input = document.getElementById('navigation_main')
  const dropdownButtons = document.getElementsByClassName('header-link--has-children')
  const dropdowns = document.getElementsByClassName('header-navigation_item')

  document.addEventListener('click', function() {
    closeAllDropdowns(dropdowns)
  })

  if(nav != undefined && input != undefined) {

    input.addEventListener('change', function(e) {
      e.stopImmediatePropagation();
      if(input.checked && !nav.classList.contains('open')) {
        nav.classList.add('open')
        body.classList.add('no-scroll')
      } else {
        nav.classList.remove('open')
        body.classList.remove('no-scroll')
      }
    })

    Array.from(dropdownButtons).forEach(function(button) {
      button.addEventListener('click', function(e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        if (button.parentElement.classList.contains('open')) {
          e.target.blur()
          button.parentElement.classList.remove('open')
        } else {
          closeAllDropdowns(dropdowns)
          button.parentElement.classList.toggle('open')
        }

        //  This has to go as it causes some troubles on webkit
        // if (button.parentElement.classList.contains('open')) {
        //   u.onClickOutside(button, () => closeAllDropdowns(dropdowns))
        // }
      })
    })
  }
})

const closeAllDropdowns = function(elements) {
  if (window.innerWidth >= 950) {
    Array.from(elements).forEach(function(el) {
      el.classList.contains('open') ? el.classList.remove('open') : null
    })
  }
}
